exports.components = {
  "component---src-components-redirect-component-redirect-component-jsx": () => import("./../../../src/components/RedirectComponent/RedirectComponent.jsx" /* webpackChunkName: "component---src-components-redirect-component-redirect-component-jsx" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-templates-accountant-page-accountant-page-jsx": () => import("./../../../src/templates/AccountantPage/AccountantPage.jsx" /* webpackChunkName: "component---src-templates-accountant-page-accountant-page-jsx" */),
  "component---src-templates-blog-blog-jsx": () => import("./../../../src/templates/Blog/Blog.jsx" /* webpackChunkName: "component---src-templates-blog-blog-jsx" */),
  "component---src-templates-blog-blog-subpage-blog-subpage-jsx": () => import("./../../../src/templates/Blog/BlogSubpage/BlogSubpage.jsx" /* webpackChunkName: "component---src-templates-blog-blog-subpage-blog-subpage-jsx" */),
  "component---src-templates-contact-contact-jsx": () => import("./../../../src/templates/Contact/Contact.jsx" /* webpackChunkName: "component---src-templates-contact-contact-jsx" */),
  "component---src-templates-contact-form-contact-form-jsx": () => import("./../../../src/templates/ContactForm/ContactForm.jsx" /* webpackChunkName: "component---src-templates-contact-form-contact-form-jsx" */),
  "component---src-templates-documents-documents-jsx": () => import("./../../../src/templates/Documents/Documents.jsx" /* webpackChunkName: "component---src-templates-documents-documents-jsx" */),
  "component---src-templates-elektronikus-fizetes-elektronikus-fizetes-jsx": () => import("./../../../src/templates/ElektronikusFizetes/ElektronikusFizetes.jsx" /* webpackChunkName: "component---src-templates-elektronikus-fizetes-elektronikus-fizetes-jsx" */),
  "component---src-templates-index-index-jsx": () => import("./../../../src/templates/Index/index.jsx" /* webpackChunkName: "component---src-templates-index-index-jsx" */),
  "component---src-templates-new-product-pages-new-product-pages-jsx": () => import("./../../../src/templates/NewProductPages/NewProductPages.jsx" /* webpackChunkName: "component---src-templates-new-product-pages-new-product-pages-jsx" */),
  "component---src-templates-online-penztargep-online-penztargep-jsx": () => import("./../../../src/templates/OnlinePenztargep/OnlinePenztargep.jsx" /* webpackChunkName: "component---src-templates-online-penztargep-online-penztargep-jsx" */),
  "component---src-templates-online-szamlazo-online-szamlazo-jsx": () => import("./../../../src/templates/OnlineSzamlazo/OnlineSzamlazo.jsx" /* webpackChunkName: "component---src-templates-online-szamlazo-online-szamlazo-jsx" */),
  "component---src-templates-order-thank-you-order-thank-you-jsx": () => import("./../../../src/templates/OrderThankYou/OrderThankYou.jsx" /* webpackChunkName: "component---src-templates-order-thank-you-order-thank-you-jsx" */),
  "component---src-templates-penztargep-feloldas-penztargep-feloldas-jsx": () => import("./../../../src/templates/PenztargepFeloldas/PenztargepFeloldas.jsx" /* webpackChunkName: "component---src-templates-penztargep-feloldas-penztargep-feloldas-jsx" */),
  "component---src-templates-popups-popups-jsx": () => import("./../../../src/templates/Popups/Popups.jsx" /* webpackChunkName: "component---src-templates-popups-popups-jsx" */),
  "component---src-templates-privacy-policy-privacy-policy-jsx": () => import("./../../../src/templates/PrivacyPolicy/PrivacyPolicy.jsx" /* webpackChunkName: "component---src-templates-privacy-policy-privacy-policy-jsx" */),
  "component---src-templates-product-categories-product-categories-jsx": () => import("./../../../src/templates/ProductCategories/ProductCategories.jsx" /* webpackChunkName: "component---src-templates-product-categories-product-categories-jsx" */),
  "component---src-templates-product-category-product-category-jsx": () => import("./../../../src/templates/ProductCategory/ProductCategory.jsx" /* webpackChunkName: "component---src-templates-product-category-product-category-jsx" */),
  "component---src-templates-product-pages-product-pages-jsx": () => import("./../../../src/templates/ProductPages/ProductPages.jsx" /* webpackChunkName: "component---src-templates-product-pages-product-pages-jsx" */),
  "component---src-templates-qvik-qvik-jsx": () => import("./../../../src/templates/Qvik/Qvik.jsx" /* webpackChunkName: "component---src-templates-qvik-qvik-jsx" */),
  "component---src-templates-request-form-request-form-jsx": () => import("./../../../src/templates/RequestForm/RequestForm.jsx" /* webpackChunkName: "component---src-templates-request-form-request-form-jsx" */),
  "component---src-templates-retailer-page-retailer-page-jsx": () => import("./../../../src/templates/RetailerPage/RetailerPage.jsx" /* webpackChunkName: "component---src-templates-retailer-page-retailer-page-jsx" */),
  "component---src-templates-szechenyi-szechenyi-jsx": () => import("./../../../src/templates/Szechenyi/Szechenyi.jsx" /* webpackChunkName: "component---src-templates-szechenyi-szechenyi-jsx" */),
  "component---src-templates-to-c-to-c-jsx": () => import("./../../../src/templates/ToC/ToC.jsx" /* webpackChunkName: "component---src-templates-to-c-to-c-jsx" */),
  "component---src-templates-vendeglatas-vendeglatas-jsx": () => import("./../../../src/templates/Vendeglatas/Vendeglatas.jsx" /* webpackChunkName: "component---src-templates-vendeglatas-vendeglatas-jsx" */)
}

